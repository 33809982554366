import revive_payload_client_GUWlgTh0GH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_oLrhUCoXJh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9rLoMUI0vE from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tUiHJLvUk4 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@20.17.6_sa_2df6cc9322ddb908396806d90366e2f9/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_DqUEcPqb55 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PuElkzuOoj from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_KFTX8jYLCE from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_02iULe4nHC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_s6UOM6FXzE from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.25.0_typescript@5.5.3_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_j9ninGnGvs from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.0_ioredis@5.4.1_mag_13310f4cc867fea39bb83513b75fa4c9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tt1tBqOgYo from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.14.159_@parcel+watcher@2.5.0_@ty_b806d11bac42428d8c3288aa9bdd8347/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_78sFGH7CAm from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.25.0_typescript@5.5.3_vue@3.5.12__8a713e297f052df0e4fea8dc6302a721/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_ZJbkuF1IfU from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XlU0betbmE from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import leaflet_client_trb2AG60Fs from "/opt/build/repo/plugins/leaflet.client.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_GUWlgTh0GH,
  unhead_oLrhUCoXJh,
  router_9rLoMUI0vE,
  _0_siteConfig_tUiHJLvUk4,
  payload_client_DqUEcPqb55,
  navigation_repaint_client_PuElkzuOoj,
  check_outdated_build_client_KFTX8jYLCE,
  chunk_reload_client_02iULe4nHC,
  plugin_vue3_s6UOM6FXzE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_j9ninGnGvs,
  plugin_tt1tBqOgYo,
  plugin_78sFGH7CAm,
  switch_locale_path_ssr_ZJbkuF1IfU,
  i18n_XlU0betbmE,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  leaflet_client_trb2AG60Fs,
  sentry_client_GoGQuZo4Et
]